import React, { useState } from 'react';
import { generateClient } from "aws-amplify/api";
import { createWaitlistSubscriber } from '../graphql/mutations';


const client = generateClient()
const Modal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  // Function to handle background click
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await client.graphql({
        query: createWaitlistSubscriber,
        variables: {
          input: {
            email: email,
          },
        },
      });
      setMessage('Success! You have been added to the waitlist.');
      setMessageColor('text-green-500');
      setEmail(''); // Clear the email input field
    } catch (error) {
      setMessage('Error! Please try again.');
      setMessageColor('text-red-500');
    }
  };

  return (
    <div className="fixed inset-0 bg-[#fec897] flex justify-center items-center" onClick={handleBackgroundClick}>
      <div className="bg-[#fde9c8] p-4 rounded-xl shadow-2xl" style={{ boxShadow: '0 0 50px #ff8661' }}>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <p className="text-lg font-semibold font-mono">Enter Email to Join the Waitlist</p>
          </div>
          <input
            type="email"
            placeholder="karl@chanel.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-2"
            required
          />
          <button type="submit" className="w-full bg-[#FF8661] text-[#F2F8F0] p-2 rounded flex items-center justify-center">
            <span className="mr-2 font-mono">Join</span>
          </button>
          {message && <p className={`mt-4 ${messageColor}`}>{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default Modal;
