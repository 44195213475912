// src/App.js
import React from 'react';
import LandingPage from './components/LandingPage';

function App() {
  return (
    <LandingPage />
  );
}

export default App;
