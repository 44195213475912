import React, { useState } from 'react';
import Modal from './Modal';

const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="h-screen flex justify-center items-center bg-[#fec897]">
      <div className="flex flex-col items-center">
        <p className="cursor-pointer text-7xl" onClick={openModal}>🍑</p>
        <p className="font-mono mt-2">
          Redefining the future of e-commerce.
        </p>
      </div>
      {isModalOpen && <Modal onClose={closeModal} />}
    </div>
  );
};

export default LandingPage;
