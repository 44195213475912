/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createWaitlistSubscriber = /* GraphQL */ `
  mutation CreateWaitlistSubscriber(
    $input: CreateWaitlistSubscriberInput!
    $condition: ModelWaitlistSubscriberConditionInput
  ) {
    createWaitlistSubscriber(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWaitlistSubscriber = /* GraphQL */ `
  mutation UpdateWaitlistSubscriber(
    $input: UpdateWaitlistSubscriberInput!
    $condition: ModelWaitlistSubscriberConditionInput
  ) {
    updateWaitlistSubscriber(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWaitlistSubscriber = /* GraphQL */ `
  mutation DeleteWaitlistSubscriber(
    $input: DeleteWaitlistSubscriberInput!
    $condition: ModelWaitlistSubscriberConditionInput
  ) {
    deleteWaitlistSubscriber(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
